import { AssetProps, GENDER } from "../../type/type";

export const hairs: AssetProps[] = [
    {name: 'Court 1', key: 'short_1', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/court.png', icon: 'assets/textures/hairs/female/icon_court.png'},
    {name: 'Court 2', key: 'short_2', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/court_2.png', icon: 'assets/textures/hairs/female/icon_court_2.png'},
    {name: 'Court 3', key: 'short_3', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/court_3.png', icon: 'assets/textures/hairs/female/icon_court_3.png'},
    {name: 'Court 4', key: 'short_4', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/court_4.png', icon: 'assets/textures/hairs/female/icon_court_4.png'},
    {name: 'Court 5', key: 'short_5', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/court_2.png', icon: 'assets/textures/hairs/female/icon_court_2.png'},
    {name: 'Très Long 1', key: 'extralong_1', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/extra_long.png', icon: 'assets/textures/hairs/female/icon_extra_long.png'},
    {name: 'Très Long 2', key: 'extralong_2', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/extra_long_2.png', icon: 'assets/textures/hairs/female/icon_extra_long_2.png'},
    {name: 'Très Long 3', key: 'extralong_3', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/extra_long_3.png', icon: 'assets/textures/hairs/female/icon_extra_long_3.png'},
    {name: 'Très Long 4', key: 'extralong_4', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/extra_long_4.png', icon: 'assets/textures/hairs/female/icon_extra_long_4.png'},
    {name: 'Très Long 5', key: 'extralong_5', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/extra_long_5.png', icon: 'assets/textures/hairs/female/icon_extra_long_5.png'},
    {name: 'Long 1', key: 'long_1', gender: GENDER.FEMALE, assets: 'assets/textures/hairs/female/long.png', icon: 'assets/textures/hairs/female/icon_long.png'},
    {name: 'Court 1', key: 'short_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/short.png', icon: 'assets/textures/hairs/male/icon_short.png'},
    {name: 'Court 2', key: 'short_2', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/short_2.png', icon: 'assets/textures/hairs/male/icon_short_2.png'},
    {name: 'Court 3', key: 'short_3', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/short_3.png', icon: 'assets/textures/hairs/male/icon_short_3.png'},
    {name: 'Court 4', key: 'short_4', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/short_4.png', icon: 'assets/textures/hairs/male/icon_short_4.png'},
    {name: 'Calvitie 1', key: 'calvitie_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/calvitie_1.png', icon: 'assets/textures/hairs/male/icon_calvitie_1.png'},
    {name: 'Calvitie 2', key: 'calvitie_2', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/calvitie_2.png', icon: 'assets/textures/hairs/male/icon_calvitie_2.png'},
    {name: 'Calvitie 3', key: 'calvitie_3', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/calvitie_3.png', icon: 'assets/textures/hairs/male/icon_calvitie_3.png'},
    {name: 'Calvitie 4', key: 'calvitie_4', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/calvitie_4.png', icon: 'assets/textures/hairs/male/icon_calvitie_4.png'},
    {name: 'Calvitie 5', key: 'calvitie_5', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/calvitie_5.png', icon: 'assets/textures/hairs/male/icon_calvitie_5.png'},
    {name: 'Calvitie 6', key: 'calvitie_6', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/calvitie_6.png', icon: 'assets/textures/hairs/male/icon_calvitie_6.png'},
    {name: 'Crète 1', key: 'crete_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/crete.png', icon: 'assets/textures/hairs/male/icon_crete.png'},
    {name: 'Chauve 1', key: 'chauve_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/chauve.png', icon: 'assets/textures/hairs/male/icon_chauve.png'},
    {name: 'Dégradé 1', key: 'degrade_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/degrade.png', icon: 'assets/textures/hairs/male/icon_degrade.png'},
    {name: 'Dreadlocks 1', key: 'dreadlocks_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/dreadlocks.png', icon: 'assets/textures/hairs/male/icon_dreadlocks.png'},
    {name: 'Long 1', key: 'long_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/long.png', icon: 'assets/textures/hairs/male/icon_long.png'},
    {name: 'Long 2', key: 'long_2', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/long_2.png', icon: 'assets/textures/hairs/male/icon_long_2.png'},
    {name: 'Long 3', key: 'long_3', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/long_3.png', icon: 'assets/textures/hairs/male/icon_long_3.png'},
    {name: 'Long 4', key: 'long_4', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/long_4.png', icon: 'assets/textures/hairs/male/icon_long_4.png'},
    {name: 'Long 5', key: 'long_5', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/long_5.png', icon: 'assets/textures/hairs/male/icon_long_5.png'},
    {name: 'Moyen 1', key: 'mid_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid.png', icon: 'assets/textures/hairs/male/icon_mid.png'},
    {name: 'Moyen 2', key: 'mid_2', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_2.png', icon: 'assets/textures/hairs/male/icon_mid_2.png'},
    {name: 'Moyen 3', key: 'mid_3', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_3.png', icon: 'assets/textures/hairs/male/icon_mid_3.png'},
    {name: 'Moyen 4', key: 'mid_4', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_4.png', icon: 'assets/textures/hairs/male/icon_mid_4.png'},
    {name: 'Moyen 5', key: 'mid_5', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_5.png', icon: 'assets/textures/hairs/male/icon_mid_5.png'},
    {name: 'Moyen 6', key: 'mid_6', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_6.png', icon: 'assets/textures/hairs/male/icon_mid_6.png'},
    {name: 'Mi long 1', key: 'mid_long_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_long.png', icon: 'assets/textures/hairs/male/icon_mid_long.png'},
    {name: 'Mi long 2', key: 'mid_long_2', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_long_2.png', icon: 'assets/textures/hairs/male/icon_mid_long_2.png'},
    {name: 'Mi long 3', key: 'mid_long_3', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_long_3.png', icon: 'assets/textures/hairs/male/icon_mid_long_3.png'},
    {name: 'Mi long 4', key: 'mid_long_4', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/mid_long_4.png', icon: 'assets/textures/hairs/male/icon_mid_long_4.png'},
    {name: 'Très Long 1', key: 'extralong_1', gender: GENDER.MALE, assets: 'assets/textures/hairs/male/very_long.png', icon: 'assets/textures/hairs/male/icon_very_long.png'},
];

export const hairColors = [
    '#FFD6A9',
    '#E3A86F',
    '#D38138',
    '#FFEEB9',
    '#F6D059',
    '#F5B31A',
    '#965C29',
    '#AC5300',
    '#783200',
    '#D6D6D6',
    '#918D98',
    '#4F4A55',
    '#F29159',
    '#9E3D3B',
    '#584B39',
    '#FF8746',
    '#FF6000',
    '#DF3800',
    '#2F2E2E',
    '#FFF392',
    '#FFE700',
    '#FFE3F8',
    '#FFB0E0',
    '#3A1705',
    '#774320',
    '#CC8B33',
    '#E5BA6A',
    '#F4D987',
    '#71584A',
    '#AC8964',
    '#333333',
    '#111111',
];

export const eyes: AssetProps[] = [
    {name: 'Oeil 1', key: 'eye_1', assets: 'assets/textures/eyes/eyes.png'},
];

export const eyesColors = [
    '#322D38',
    '#49313D',
    '#644243',
    '#714448',
    '#80624C',
    '#85654A',
    '#7D5246',
    '#965D46',
    '#766C4C',
    '#8D7964',
    '#5E4D47',
    '#837150',
    '#626E5B',
    '#454A4A',
    '#5E6750',
    '#526E73',
    '#646366',
    '#A0948F',
    '#6C7A75',
    '#5385B5',
    '#7A8FA2',
    '#99B8B3',
    '#79A4B3',
    '#76998D',
    '#B4CAD4',
];

export const eyesBrown: AssetProps[] = [
    {name: 'Normal', key: 'normal', assets: 'assets/textures/sourcils/normal.png', icon: 'assets/textures/sourcils/icon_normal.png'},
    {name: 'Normal Haut', key: 'normal_top', assets: 'assets/textures/sourcils/normal_top.png', icon: 'assets/textures/sourcils/icon_normal_top.png'},
    {name: 'Long', key: 'long', assets: 'assets/textures/sourcils/long.png', icon: 'assets/textures/sourcils/icon_long.png'},
    {name: 'Grand et Haut ', key: 'big_top', assets: 'assets/textures/sourcils/big_top.png', icon: 'assets/textures/sourcils/icon_big_top.png'},
    {name: 'Grand et Bas ', key: 'big_bottom', assets: 'assets/textures/sourcils/big_bottom.png', icon: 'assets/textures/sourcils/icon_big_bottom.png'},
    {name: 'Sans', key: 'without', assets: 'assets/textures/sourcils/without.png', icon: 'assets/textures/sourcils/icon_without.png'},
];

export const beards: AssetProps[] = [
    {gender: GENDER.MALE, name: 'Rasé', key: 'without', assets: 'assets/textures/beards/chauve.png', icon: 'assets/textures/beards/icon_chauve.png'},
    {gender: GENDER.MALE, name: '2 Jours', key: '2_day', assets: 'assets/textures/beards/2jours.png', icon: 'assets/textures/beards/icon_2jours.png'},
    {gender: GENDER.MALE, name: '3 Jours', key: '3_day', assets: 'assets/textures/beards/3jours.png', icon: 'assets/textures/beards/icon_3jours.png'},
    {gender: GENDER.MALE, name: '7 Jours', key: '7_day', assets: 'assets/textures/beards/7jours.png', icon: 'assets/textures/beards/icon_7jours.png'},
    {gender: GENDER.MALE, name: 'Grande', key: 'big', assets: 'assets/textures/beards/grande.png', icon: 'assets/textures/beards/icon_grande.png'},
    {gender: GENDER.MALE, name: 'Longue', key: 'long', assets: 'assets/textures/beards/long.png', icon: 'assets/textures/beards/icon_long.png'},
    {gender: GENDER.MALE, name: 'Maria', key: 'mario', assets: 'assets/textures/beards/mario.png', icon: 'assets/textures/beards/icon_mario.png'},
    {gender: GENDER.MALE, name: 'Mi longue', key: 'mid', assets: 'assets/textures/beards/mid.png', icon: 'assets/textures/beards/icon_mid.png'},
    {gender: GENDER.MALE, name: 'Monopoly', key: 'monopoly', assets: 'assets/textures/beards/monopoly.png', icon: 'assets/textures/beards/icon_monopoly.png'},
    {gender: GENDER.MALE, name: 'Moustache', key: 'moustache', assets: 'assets/textures/beards/moustache.png', icon: 'assets/textures/beards/icon_moustache.png'},
    {gender: GENDER.MALE, name: 'Jamais coupé', key: 'nevercut', assets: 'assets/textures/beards/nevercut.png', icon: 'assets/textures/beards/icon_nevercut.png'},
    {gender: GENDER.MALE, name: 'Scientifique', key: 'science', assets: 'assets/textures/beards/science.png', icon: 'assets/textures/beards/icon_science.png'},
    {gender: GENDER.MALE, name: 'Courte', key: 'short', assets: 'assets/textures/beards/short.png', icon: 'assets/textures/beards/icon_short.png'},
    {gender: GENDER.MALE, name: 'Steve', key: 'steve', assets: 'assets/textures/beards/steve.png', icon: 'assets/textures/beards/icon_steve.png'},
    {gender: GENDER.MALE, name: 'Rouflaquette', key: 'side', assets: 'assets/textures/beards/side.png', icon: 'assets/textures/beards/icon_side.png'},
    {gender: GENDER.MALE, name: 'Grande Rouflaquette', key: 'superside', assets: 'assets/textures/beards/superside.png', icon: 'assets/textures/beards/icon_superside.png'},
    {gender: GENDER.MALE, name: 'Super Rouflaquette', key: 'ultraside', assets: 'assets/textures/beards/ultraside.png', icon: 'assets/textures/beards/icon_ultraside.png'},
];

export const skinColors = [
    '#F6C68D',
    '#E1A976',
    '#C48F5F',
    '#AD7646',
    '#905B2F',
    '#6C4527',
    '#F5BF77',
    '#EFA74C',
    '#D59545',
    '#ECCAAD',
    '#EBAB85',
    '#EC9476',
    '#DECA94',
    '#E1C578',
    '#CEB067',
    '#DDBF6E',
    '#BF934D',
    '#9C8869',
    '#AA6952',
    '#905039',
    '#643523',
    '#CBC2BA',
    '#422918',
];

export const skinShape: AssetProps[] = [
    {name: 'Normal', key: 'normal', assets: 'assets/textures/skin/men_normal_0.png', icon: 'assets/textures/skin/icon_men_normal_0.png'},
    {name: 'Gros 1', key: 'fat_1', assets: 'assets/textures/skin/men_fat_1.png', icon: 'assets/textures/skin/icon_men_fat_1.png'},
    {name: 'Gros 2', key: 'fat_2', assets: 'assets/textures/skin/men_fat_2.png', icon: 'assets/textures/skin/icon_men_fat_2.png'},
    {name: 'Gros 3', key: 'fat_3', assets: 'assets/textures/skin/men_fat_3.png', icon: 'assets/textures/skin/icon_men_fat_3.png'},
    {name: 'Musclé 1', key: 'muscle_1', assets: 'assets/textures/skin/men_muscle_1.png', icon: 'assets/textures/skin/icon_men_muscle_1.png'},
    {name: 'Musclé 2', key: 'muscle_2', assets: 'assets/textures/skin/men_muscle_2.png', icon: 'assets/textures/skin/icon_men_muscle_2.png'},
    {name: 'Musclé 3', key: 'muscle_3', assets: 'assets/textures/skin/men_muscle_3.png', icon: 'assets/textures/skin/icon_men_muscle_3.png'},
];